// v1.0.4 10-12-2024
"use client";

// React
import React, { useEffect, useState } from "react";

// Layout
import Footer from "@/components/Footer";
import SidebarMenu from "@/components/SidebarMenu";
import Navbar from "@/components/Navbar";

// pathname
import { usePathname } from "next/navigation";

// Toasts
import { Toaster } from "sonner";
type LayoutClientProps = {
  children: React.ReactNode;
};

const regexPagesWithoutLayout: RegExp[] = [];
const LayoutClient: React.FC<LayoutClientProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLayout, setShowLayout] = useState(true);
  const pathName = usePathname();

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (regexPagesWithoutLayout.some((regex) => regex.test(pathName))) {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }
  }, [pathName]);

  return (
    <>
      {!showLayout ? (
        children
      ) : (
        <main className="flex min-h-full h-max dark:bg-dark-mode">
          <SidebarMenu isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
          <section className="flex flex-col min-h-screen w-full justify-between">
            <Navbar
              fqcn_bui={{
                Bundle: `${process.env.NEXT_PUBLIC_APP_ID}Bundle`,
                Unit: "navbar",
                Interface: "pageHeader",
              }}
            />
            <div className="flex flex-col items-center h-full mb-56 md:mb-40">
              {children}
            </div>
            <Toaster />
            <Footer
              fqcn_bui={{
                Bundle: `${process.env.NEXT_PUBLIC_APP_ID}Bundle`,
                Unit: "footer",
                Interface: "pageFooter",
              }}
            />
          </section>
        </main>
      )}
    </>
  );
};

export default LayoutClient;
